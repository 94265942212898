import React, {useContext, useEffect, useState} from "react";
import {Link, useOutletContext, useParams} from 'react-router-dom';
import AuthContext from "../../../services/AuthContext";
import EditMember from "../../../Panels/Member/EditMember";
import IconButton from "@mui/material/IconButton";
import {
    CheckBox, CheckBoxOutlineBlank,
    Clear,
    Delete,
    Edit,
    FileDownload,
    IndeterminateCheckBox,
    Phonelink,
    Remove,
    ViewArray,
    ViewList
} from "@mui/icons-material";
import {DetailsList, SearchBox, SelectionMode, Stack} from "@fluentui/react";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import {CSVDownloader} from "react-papaparse";
import currency from "currency.js";
import {Button, ButtonGroup} from "@mui/material";

function Active() {
    const params = useParams();
    const query = useOutletContext();
    const [filtered, setFiltered] = useState([]);
    const [user] = useContext(AuthContext);
    const [members, setMembers] = useState([]);
    const [summary, setSummary] = useState('');
    const [mode, setMode] = useState('default');
    const [meals, setMeals] = useState([]);
    const [columns, setColumns] = useState([]);

    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (query === '') setFiltered(members);
        else {
            setFiltered(members.filter(member => member.name.toLowerCase().includes(query.toLowerCase()) || member.department.toLowerCase().includes(query.toLowerCase())));
        }
    }, [query, members]);

    const summaryColumn = summary !== '' ? [{
        key: 'balance',
        name: 'Saldo',
        ariaLabel: 'Saldo',
        fieldName: 'balance',
        minWidth: 80,
        isResizable: true,
        onRender: (item) => (
            <MDTypography color={item.balance < 0 ? 'error' : 'secondary'}>{item.balance} zł</MDTypography>)
    }, {
        key: 'actions',
        name: 'Akcje',
        ariaLabel: 'Akcje',
        fieldName: 'actions',
        minWidth: 80,
        onRender: _makeButtons
    }] : [];

    useEffect(() => {
        if (mode === 'finances') {
            setColumns([
                {
                    key: 'fullname',
                    name: 'Imię i Nazwisko',
                    ariaLabel: 'Imię i Nazwisko',
                    fieldName: 'fullname',
                    minWidth: 120,
                    isResizable: true,
                    onRender: _memberLink
                },
                {
                    key: 'department',
                    name: 'Wydział',
                    ariaLabel: 'Wydział',
                    fieldName: 'department',
                    minWidth: 180,
                    isResizable: true,
                    onRender: _departmentLink
                },
                ...meals,
                {
                    key: 'discount',
                    name: 'Rabat za pobyt',
                    ariaLabel: 'Rabat za pobyt',
                    fieldName: 'discount',
                    minWidth: 140,
                    isResizable: true,
                },
                {
                    key: 'personal_tuition',
                    name: 'Czesne',
                    ariaLabel: 'Czesne',
                    fieldName: 'personal_tuition',
                    minWidth: 120,
                    isResizable: true,
                },
                ...summaryColumn,
            ]);
        } else {
            setColumns([
                {
                    key: 'fullname',
                    name: 'Imię i Nazwisko',
                    ariaLabel: 'Imię i Nazwisko',
                    fieldName: 'fullname',
                    minWidth: 120,
                    isResizable: true,
                    onRender: _memberLink
                },
                {
                    key: 'department',
                    name: 'Wydział',
                    ariaLabel: 'Wydział',
                    fieldName: 'department',
                    minWidth: 180,
                    isResizable: true,
                    onRender: _departmentLink
                },
                {
                    key: 'app',
                    name: 'Aplikacja',
                    ariaLabel: 'Aplikacja',
                    fieldName: 'app',
                    minWidth: 70,
                    isResizable: false,
                    onRender: (item) => item.app > 0 ? <Phonelink fontSize={'medium'}/> : ''
                },
                {
                    key: 'diet',
                    name: 'Dieta',
                    ariaLabel: 'Dieta',
                    fieldName: 'diet',
                    minWidth: 140,
                    isResizable: true,
                },
                {
                    key: 'attend_from',
                    name: 'Od',
                    ariaLabel: 'Od godziny',
                    fieldName: 'attend_from',
                    minWidth: 45,
                    isResizable: false,
                },
                {
                    key: 'attend_to',
                    name: 'Do',
                    ariaLabel: 'Do godziny',
                    fieldName: 'attend_to',
                    minWidth: 45,
                    isResizable: false,
                },
                ...summaryColumn,
            ])
        }
    }, [mode, meals])

    function _deleteMember(id) {
        fetch(`/api/member/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                setRefresh(refresh + 1);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const [editMember, setEditMember] = useState(false);

    function _makeButtons(item) {
        return (<>
            <IconButton color={'success'} onClick={() => setEditMember(item)}><Edit/></IconButton>
            <IconButton color={'error'} onDoubleClick={() => _deleteMember(item.id)}><Delete/></IconButton>
        </>);
    }

    useEffect(() => {
        const abortController = new AbortController();
        const abortController2 = new AbortController();

        async function fetchData() {
            await fetch(`/api/facility/${params.id}/members`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then((data) => {
                    setMembers(data);
                    if (data[0]?.balance !== undefined) {
                        const saldo = data.reduce((c, v) => c.add(currency(v.balance < 0 ? v.balance : 0)), currency(0, {
                            pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
                        }));
                        setSummary(<MDTypography textAlign={'right'} fontWeight={'light'}
                                                 variant={'h4'}>Zaległości: {saldo.format()}
                            <CSVDownloader data={data.filter((i) => i.balance < 0)} filename={`zaleglosci`} bom={true}
                                           config={{delimiter: ';'}}>
                                <IconButton variant={'text'}><FileDownload/></IconButton>
                            </CSVDownloader>
                        </MDTypography>);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();

        async function fetchMeals() {
            await fetch(`/api/facility/${params.id}/meal`, {
                method: 'GET',
                signal: abortController2.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then((data) => {
                    setMeals(data.meals.map((item, key) => {
                        return {
                            key: 'm' + key,
                            name: item.meal_type,
                            ariaLabel: item.meal_type,
                            fieldName: 'm' + key,
                            minWidth: 70,
                            isResizable: false,
                            onRender: (item) => item['m' + key] > 0 ?
                                <CheckBox fontSize={'medium'} color={"success"}/> :
                                item['m' + key] < 0 ? <IndeterminateCheckBox fontSize={'medium'} color={"error"}/> :
                                <CheckBoxOutlineBlank fontSize={'medium'} color={"secondary"}/>
                        };
                    }));
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchMeals();
        return () => {
            abortController.abort();
            abortController2.abort();
        }
    }, [user, params.id, refresh]);

    const _memberLink = (item) => {
        return (<Link to={`/facility/${params.id}/department/${item.department_id}/member/${item.id}`}><MDButton
            color={'info'} fullWidth>{item.surname} {item.name}</MDButton></Link>);
    }

    const _departmentLink = (item) => {
        return (<Link to={`/facility/${params.id}/department/${item.department_id}`}><MDButton color={'light'}
                                                                                               fullWidth>{item.department}</MDButton></Link>);
    }

    return (
        <div>
            {summary}
            <Stack justifyContent={'space-between'}>
                <ButtonGroup variant="text" aria-label="Tryb wyświetlania" sx={{margin: '0 auto'}}>
                    <Button onClick={() => setMode('default')} variant="text"
                            color={mode === 'default' ? 'primary' : 'secondary'}>Podsumowanie</Button>
                    <Button onClick={() => setMode('finances')} variant="text"
                            color={mode === 'finances' ? 'primary' : 'secondary'}>Finanse</Button>
                </ButtonGroup>
            </Stack>
            <DetailsList items={filtered} columns={columns} selectionMode={SelectionMode.none}/>
            <EditMember data={editMember} dismiss={() => setEditMember(false)}
                        onSuccess={() => setRefresh(refresh + 1)}/>
        </div>
    );
}

export default Active;